$('.header_burger').click(function (event) {
  $('.header_burger, .header_list').addClass('active');
  $('.mobile_header-main-logo').addClass('active');
  $('.list-menu').addClass('active');
  // $('section, footer').fadeOut();
});
$('.close-mobile_menu').click(function (event) {
  $('.header_burger, .header_list').removeClass('active');
  $('.mobile_header-main-logo').removeClass('active');
  $('.list-menu').removeClass('active');
  // $('section, footer').fadeIn();
});

$('.spec').click(function() {
  $('.spec-window').fadeIn('fast');
  $('.list-menu, .header_burger').removeClass('active');
  $('.mobile_header-main-logo').removeClass('active');

})
$('.close-popup').click(function() {
  $('.spec-window').fadeOut();
});

$(function() {
  let header = $('.upper_header');

  $(window).scroll(function() {
    if($(this).scrollTop() > 350) {
     header.addClass('fixed');
     header.fadeIn();
    } else {
     header.removeClass('fixed');
    }
  });
 });

$("a[href^='/#']").on("click", function () {
  let href = $(this).attr("href");

  $("html, body").animate({
      scrollTop: $(href).offset().top - 50
  });

  return false;
});
var swiper = new Swiper(".mySwiper", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
  },
});

var swiper = new Swiper(".swiperProduct", {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1.2,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 40,
      }
    },
  });
$(".top").click(function () {
  $("html, body").animate({
    scrollTop: 0
  }, "slow");
  return false;
});
$('.header_wrapper-left-catalog').click(function () {
  $('.catalog-menu').slideToggle();
  $('.header_wrapper-left-catalog').toggleClass('active');
});
$('.company').click(function () {
  $('.add-link-dropdown').slideToggle();
});
